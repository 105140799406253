import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import "./AnimatedSeporator.css"

const AnimatedSeporator = () => {
    const [speed, setSpeed] = useState(200); // Initial speed
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const newSpeed = 26;
      setSpeed(newSpeed);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        setSpeed(200);
      }, 200);

      setTimeoutId(newTimeoutId);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);;

  return (
    <div className="absolute w-full h-full flex justify-center items-center">
      <motion.div
      key={speed}
        className="spinning-seporator"
        animate={{ translateX: 1100 }}
        transition={{
          repeat: Infinity,
          duration: speed,
          ease: 'linear',
        }}
      />
    </div>
  );
};

export default AnimatedSeporator;