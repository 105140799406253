import React, { useState } from "react";
import { Link, scroller } from "react-scroll";
import "./Navbar.css";
import "./fonts.css";

function Navbar() {
  const [activeSection, setActiveSection] = useState("home");

  const handleSetActive = (section) => {
    setActiveSection(section);
    scroller.scrollTo(section, {
      smooth: true,
      duration: 500,
    });
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="name">Justin Naylor</div>
        <div className="nav-links">
          <Link
            to="home"
            className={`nav-link ${
              activeSection === "home" ? "active-link" : ""
            }`}
            onClick={() => handleSetActive("home")}
          >
            Home
          </Link>
          <Link
            to="about"
            className={`nav-link ${
              activeSection === "about" ? "active-link" : ""
            }`}
            onClick={() => handleSetActive("about")}
          >
            About
          </Link>
          <Link
            to="projects"
            className={`nav-link ${
              activeSection === "projects" ? "active-link" : ""
            }`}
            onClick={() => handleSetActive("projects")}
          >
            Projects
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
