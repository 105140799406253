import React from "react";
import { Element } from "react-scroll";
import { motion } from "framer-motion";
import ContactForm from "./ContactForm";
import "./ContactForm.css";

const Contact = () => {
  return (
    <Element
      name="contact"
      className="sectionContact bg-gradient-to-br from-black via-black to-slate-900"
    >
      <div className="content-wrapper">
        <div className="contact-section">
          <motion.div
            className="text-containerAbout"
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            animate={{ x: -100, opacity: 0 }}
            transition={{ duration: 0.7 }}
          >
            <h2 className="getInTouch">Get in Touch...</h2>
          </motion.div>

          <motion.div
            className="text-containerAbout"
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            animate={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.7 }}
          ></motion.div>
          <div></div>
          <ContactForm />
        </div>
      </div>
    </Element>
  );
};

export default Contact;
