import React from "react";
import { Element } from "react-scroll";
import { motion } from "framer-motion";
import "./About.css";
import "./fonts.css";

const About = () => {
  // TESTING!
  return (
    <Element
      name="about"
      className="about-section h-14 bg-gradient-to-b from-black via-black to-slate-800"
    >
      <div className="content-wrapperAbout">
        <motion.div
          initial={{ y: -80, opacity: 0 }}
          whileInView={{ opacity: 1, x: 0, y: 0 }}
          animate={{ y: -80, opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <h2 className="titleAbout">A Bit About Me...</h2>
        </motion.div>

        <motion.div
          className="text-containerAbout"
          initial={{ x: -80, opacity: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          animate={{ x: -80, opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <ul className="skill-list">
            <li className="descriptionAbout">
              Proficient in JavaScript, React, CSS, HTML, Node.JS, Express, and
              MongoDB.
            </li>
            <li className="descriptionAbout">
              Experienced in building dynamic and responsive web applications.
            </li>
            <li className="descriptionAbout">
              Excellent communication and collaboration skills, consistently{" "}
              <br></br>
              fostering positive and productive relationships in any team
              environment.
            </li>
            <li className="descriptionAbout">
              Effective problem solver and critical thinker.
            </li>
            <li className="descriptionAbout">
              Committed to continuous learning and staying updated with industry
              trends.
            </li>
          </ul>
        </motion.div>
      </div>
    </Element>
  );
};
export default About;
