import React, { useEffect } from "react";
import "./App.css";
import "./fonts.css";
import Navbar from "./Navbar"; // Import the Navbar component
import Home from "./Home"; // import the Home Page
import About from "./About"; // import the Home Page
import Projects from "./Projects"; // import the Home Page
import Contact from "./Contact"; // import the Home Page
import AnimatedSeporator from "./AnimatedSeperator";

function App() {
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollX !== 0) {
        window.scrollTo(0, window.scrollY);
      }
    };

    const handleTouchMove = (event) => {
      if (event.scale !== 1) {
        event.preventDefault();
      }
    };
    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    // Prevent horizontal scroll
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  return (
    <div>
      <Navbar className="navbar" />
      <Home />
      <div className="absolute w-full min-h-60">
        <div
          className="absolute inset-1 bg-repeat-x w-full bg-left"
          style={{
            backgroundImage: "url('https://i.ibb.co/kXSMq8k/code.jpg)",
            opacity: 0.2,
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-slate-900 via-transparent to-black"></div>
        </div>
      </div>
      <div className="spacer py-48 pb-48 bg-gradient-to-b from-slate-900 to-black"></div>

      <AnimatedSeporator />

      <About />

      <div className="absolute w-full">
        <div
          className="absolute inset-1 bg-repeat-x h-96 w-full bg-left"
          style={{
            backgroundImage: "url('https://i.ibb.co/3k3NVYm/css.png)",
            opacity: 0.2,
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-slate-800 via-transparent to-black"></div>
        </div>
      </div>
      <div className="spacer py-48 pb-48 bg-gradient-to-b from-slate-800 to-black"></div>
      <Projects />

      <div className="absolute w-full">
        <div
          className="absolute inset-1 bg-repeat-x h-96 w-full bg-center"
          style={{
            backgroundImage: "url('https://i.ibb.co/KFdLMk9/CODE-NEW2.jpg)",
            opacity: 0.15,
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-slate-800 via-transparent to-black"></div>
        </div>
        {/* <AnimatedSeporator/> */}
      </div>
      <div className="spacer py-64 pb-46 bg-gradient-to-b from-slate-800 via-black to-black"></div>

      <Contact />
    </div>
  );
}

export default App;
