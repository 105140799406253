import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const form = useRef();
const publicKey = process.env.REACT_APP_API_KEY;
    const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_g4hgp7d', 'template_r6xorel', form.current, publicKey)
      .then((result) => {
          console.log('SUCCESS!');
           setSuccessMessage('Email successfully sent!');
          setErrorMessage(''); // Clear any previous error message
      }, (error) => {
          console.log('FAILED...', error);
            setErrorMessage('Failed to send email, please try again.');
          setSuccessMessage(''); // Clear any previous success message
      });
  };

  return (
    <div className="contact-form-container">
      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <div className="form-group">
          <label htmlFor="user_name" className="form-label">Name</label>
          <input
            type="text"
            id="user_name"
            name="user_name"
            className="form-input"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="user_email" className="form-label">Email</label>
          <input
            type="email"
            id="user_email"
            name="user_email"
            className="form-input"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message" className="form-label">Message</label>
          <textarea
            id="message"
            name="message"
            className="form-textarea"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          value="Send"
          className="form-button"
        >
          Submit
        </button>
      </form>
          {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default ContactForm;
