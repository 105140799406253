import React from "react";
import { Link, Element } from "react-scroll";
import { motion } from "framer-motion";
import "./ProjectForm.css";
import ProjectCarousel from "./ProjectCarousel";

const Projects = () => {
  return (
    <Element
      name="projects"
      className="project-section section bg-gradient-to-b from-black via-black to-slate-800"
    >
      <motion.div
        className="projects-header"
        initial={{ y: -50, opacity: 0 }}
        whileInView={{ opacity: 1, x: 0, y: 0 }}
        animate={{ y: -50, opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <h1>A Few of my Recent Projects...</h1>
      </motion.div>

      {/* <Section title="" /> */}

      <div className="contact-button-container">
        <motion.div
          className="carousel"
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ opacity: 1, x: 0, y: -20 }}
          animate={{ y: 0, opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <Link
            to="contact-section"
            smooth={true}
            duration={500}
            className="contactButton"
          >
            Contact
          </Link>
        </motion.div>
      </div>
      <ProjectCarousel />
    </Element>
  );
};
export default Projects;
