import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { motion } from 'framer-motion';
import './ProjectCarousel.css';  // Import the CSS file
import img1 from './images/ourLocalHarvestProject.jpg';
import img2 from './images/justinWebsiteProject.jpg';
import img3 from './images/scoreKeeperProject.jpg';

const images = [
  { src: img1, title: 'Our Local Harvest', description: "An app built to allow users to register and search for farmer's markets in their area. Built with JavaScript, HTML, CSS, Node.JS, MongoDB, Express, Bootstrap.", url: 'https://ourlocalharvest-8cc18fa170a7.herokuapp.com/' },
  { src: img2, title: 'This Website!', description: 'Hey, a lot of work went into this website! Built with React and Tailwind css.', url: 'https://www.justin-naylor.com/' },
  { src: img3, title: 'Card Game Score Keeper', description: 'A simple scorekeeper for tracking points in a card game called "Sweep". Built with vanilla JavaScript and Bulma.', url: 'https://playsweep.fun/' },
];

const ProjectCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSwipeLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleSwipeRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const goToNext = () => {
    handleSwipeLeft();
  };

  const goToPrevious = () => {
    handleSwipeRight();
  };
    const goToIndex = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div {...handlers} className="carousel-container">
         <motion.div
          className="carousel"
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ opacity: 1, x: 0, y:0 }}
          animate={{ x: -100, opacity: 0 }}
          transition={{ duration: 1 }}
        >
      <div className="carousel-wrapper">
        <div
          className="carousel-content"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div className="carousel-item" key={index}>
              <img src={image.src} alt={image.description} className="carousel-image" draggable="false"/>
            </div>
          ))}
        </div>
      </div>
      <div className="carousel-info">
        <h2 className="carousel-title">{images[currentIndex].title}</h2>
        <p className="carousel-description">{images[currentIndex].description}</p>
        <a href={images[currentIndex].url} target="_blank" rel="noopener noreferrer" className="carousel-link">Go to Project</a>
      </div>
 <div className="carousel-indicators">
        {images.map((_, index) => (
          <div
            key={index}
            className={`indicator-dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToIndex(index)}
          />
        ))}
      </div>
      <button
        onClick={goToPrevious}
        className="carousel-button carousel-button-left"
      >
        &lt;
      </button>
      <button
        onClick={goToNext}
        className="carousel-button carousel-button-right"
      >
        &gt;
      </button>
      </motion.div>
    </div>
  );
};

export default ProjectCarousel;