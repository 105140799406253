import React from "react";
import { Element } from "react-scroll";
import { motion } from "framer-motion";
import "./Home.css";
import "./fonts.css";
import justinPicture from "./images/justinPicture.png";
import SpinningDottedCircle from "./SpinningDottedCircle";

const Home = () => {
  return (
    <Element
      name="home"
      className="section h-14 bg-gradient-to-b from-black to-slate-900"
    >
      <div className="content-wrapper">
        <motion.div
          className="profile-text"
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ opacity: 1, x: 0, y: 0 }}
          animate={{ x: -100, opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="title">Hi, I'm Justin Naylor</h1>
          <h2 className="description">
            I'm a passionate front-end web developer.
          </h2>
        </motion.div>

        <motion.div
          className="profile-picture"
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ opacity: 1, x: 0, y: 0 }}
          animate={{ y: 100, opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <img src={justinPicture} alt="Profile" className="w-96 h-96" />
          <SpinningDottedCircle />
        </motion.div>
      </div>
    </Element>
  );
};
export default Home;
